/**
 * Created V/08/05/2009
 * Updated V/17/10/2023
 *
 * Copyright 2008-2025 | Fabrice Creuzot (luigifab) <code~luigifab~fr>
 * https://github.com/luigifab/apijs
 *
 * This program is free software, you can redistribute it or modify
 * it under the terms of the GNU General Public License (GPL) as published
 * by the free software foundation, either version 2 of the license, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but without any warranty, without even the implied warranty of
 * merchantability or fitness for a particular purpose. See the
 * GNU General Public License (GPL) for more details.
 */

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& conteneurs &&& */
div[id="apijsDialog"] * { margin:0; padding:0; }

#apijsDialog {
	position:fixed; top:0; right:0; bottom:0; left:0; width:100%; height:100%; margin:0; padding:0; text-align:center; z-index:99000;
	direction:ltr; user-select:none; -moz-user-select:none; -ms-user-select:none; -webkit-user-select:none;
	border:0; transition:background-color 0.5s, opacity 0.25s; opacity:0;
}

#apijsDialog.ready { background-color:rgba(0, 0, 0, 0.65); opacity:1; }
#apijsDialog.fullscreen .nofullscreen { display:none !important; }
#apijsDialog.progress, #apijsDialog.waiting, #apijsDialog.loading { cursor:progress; }
#apijsDialog.reduce, #apijsDialog.reduce * { transition:none !important; }

#apijsDialog button, #apijsDialog label { cursor:pointer; }
#apijsDialog button:disabled { cursor:default; }

#apijsBox {
	position:relative; top:21%; display:inline-block; padding:0.6em 0.8em 2.4em; width:26rem; max-width:26rem; min-height:9em; overflow:hidden;
	text-align:left; color:black; border:0; border-radius:0.8em; background-color:white; box-shadow:0 0 2.5em #111;
}

#apijsBox.photo, #apijsBox.video {
	display:flex; flex-direction:column;
	position:absolute; top:0; right:0; bottom:0; left:0; padding:0; width:auto; max-width:inherit; min-height:inherit; text-align:center;
	border-radius:0; background-color:rgba(0, 0, 0, 0.8); box-shadow:none;
}

#apijsBox.video { background-color:black; }
#apijsBox.photo.error, #apijsBox.video.error { background-image:url("../images/apijs/tv.gif"); background-repeat:repeat; }
#apijsBox.options, #apijsBox.upload, #apijsBox.progress { min-height:15em; }
#apijsBox.iframe, #apijsBox.ajax { padding:2.5em 0.8em 1em; }

#apijsBox.mobile:not(.photo):not(.video) { position:absolute; top:1.5em; right:0.5em; left:0.5em; width:auto; min-height:inherit; }
#apijsBox.tiny div.navigation button { padding:0 1em; }

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& glisser/déposer &&& */
#apijsDialog.drag:before {
	position:absolute; top:1em; right:1em; bottom:1em; left:1em; content:"";
	border: 2px dashed white;
}

#apijsDialog p.drag { position:absolute; right:0; bottom:0; margin:1.5em; font-size:1.3em; color:white; }

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& texte &&& */
#apijsBox div.bbcode { user-select:text; -moz-user-select:text; -ms-user-select:text; -webkit-user-select:text; }

#apijsBox h1  { margin:0.15em 0 0.7em; height:32px; line-height:32px; font-size:1.49em; font-weight:700; }
#apijsBox pre { margin:1em auto; line-height:1.42857143; font-size:0.7rem; white-space:pre-wrap; }
#apijsBox p   { margin:1em auto; line-height:1.42857143; font-size:1em; }
#apijsBox ul  { margin:-0.4em auto 1em; }

#apijsBox iframe { display:block; width:100%; border:0; }
#apijsBox iframe.loading { pointer-events:none; }

#apijsBox.progress p.anim:before {
	content:attr(data-old);
	-webkit-animation:apijsReplace 1s linear 2s forwards; animation:apijsReplace 1s linear 2s forwards;
}

#apijsBox.progress span.info { position:absolute; right:1em; bottom:0.8em; }

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& barre de progression &&& */
#apijsBox.progress svg {
	position:relative; display:block; margin:18px auto; width:80%; height:20px; overflow:hidden;
	outline:1px solid #333; background-color:#AAA;
}

#apijsBox.progress rect {
	position:absolute; top:0; bottom:0; left:0; display:block;
	background:#03FF00 repeating-linear-gradient(-45deg, #03FF00 0, #03FF00 10px, #00EF00 10px, #00EF00 20px); background-size:28px 100%;
}

#apijsBox.progress rect.auto { width:20%; -webkit-animation:apijsProgress 10s infinite linear; animation:apijsProgress 10s infinite linear; }
#apijsBox.progress rect.end { width:100%; -webkit-animation:apijsProgress 10s infinite 3s linear; animation:apijsProgress 10s infinite 3s linear; }

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& image d'attente &&& **
 * (loading) waiting photo video iframe ajax */
#apijsBox svg.loader { position:absolute; display:none; margin:auto; width:128px; height:128px; pointer-events:none; }
#apijsBox svg.loader path { fill:#808080; }

#apijsBox.loading svg.loader, #apijsBox.waiting svg.loader {
	display:block; right:-40px; bottom:-40px;
	transform:scale(0.2); -webkit-transform:scale(0.2);
}

#apijsBox.photo svg.loader, #apijsBox.video svg.loader { right:0; bottom:calc(50% - 64px); left:0; transform:none; -webkit-transform:none; }
#apijsBox.iframe svg.loader, #apijsBox.ajax svg.loader { right:0; bottom:calc(50% - 64px); left:0; }

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& boutons &&& */
#apijsBox button {
	font-size:0.85em; text-decoration:none; border:0;
	transition:color 0.4s, border-color 0.4s, background-color 0.4s, opacity 0.4s;
}

#apijsBox button::-moz-focus-inner { padding:0; outline:0; border:0; }
#apijsBox button:hover, #apijsBox button:active, #apijsBox button:focus { outline:0; }

#apijsBox div.btns { position:absolute; right:0.8em; bottom:0.6em; }
#apijsBox div.btns.upload { position:relative; right:inherit; left:inherit; }
#apijsBox div.btns.upload input { display:none; }
#apijsBox div.btns.upload div.filenames { max-height:11em; overflow-y:auto; }
#apijsBox div.btns.upload table { border-spacing:0; }
#apijsBox div.btns.upload td + td { padding-left:0.6em; }
#apijsBox div.btns.upload td.nb   { font-size:0.85em; color:#676767; vertical-align:bottom; }
#apijsBox div.btns.upload td.size { white-space:nowrap; color:#676767; }
#apijsBox div.btns.upload td.err  { color:#E41101; }

#apijsBox div.btns button {
	padding:0 0.7em; height:auto; line-height:24px;
	color:black; border:1px solid #BBB; border-radius:0.4em; background-color:#E2E2E2;
}

#apijsBox div.btns button:disabled { opacity:0.5; }
#apijsBox div.btns button.cancel { float:left; margin:0 0.5em; }
#apijsBox div.btns button.browse { display:inline-block; margin:0.5em 0; }

#apijsBox div.btns button:hover:not(:disabled), #apijsBox div.btns button:active:not(:disabled),
#apijsBox div.btns button:focus:not(:disabled) { border-color:#555; background-color:#F4F4F4; }
#apijsBox div.btns button:focus:not(:hover) { -webkit-animation:apijsFocus 1s infinite; animation:apijsFocus 1s infinite; }

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& dialogues photo et vidéo &&& */
#apijsBox dl.media { display:flex; flex-direction:column-reverse; flex:1; overflow:hidden; color:white; }

#apijsBox dl.media dt { position:relative; display:flex; flex-direction:column; flex:1; overflow:hidden; }
#apijsBox dl.media dt img, #apijsBox dl.media dt video, #apijsBox dl.media dt iframe { flex:1 1 auto; height:100%; visibility:hidden; }
#apijsBox dl.media dt img { image-rendering:optimizeQuality; image-rendering:-moz-crisp-edges; object-fit:scale-down; }
#apijsBox dl.media dt video { outline:0; cursor:pointer; }
#apijsBox dl.media dt div.apijsplayer { position:absolute; right:0; bottom:0; left:0; }

#apijsBox dl.media dd { line-height:1.5rem; font-size:0.85em; background-color:#666; }
#apijsBox dl.media dd span { margin:0 0.6em; font-size:0.9em; font-style:italic; }

/* &&&&&&&&&&&&&&&&&&&& bouton de fermeture &&& */
#apijsBox div.close { position:absolute; top:-2px; right:-2px; padding:0 0 3em 3em; z-index:99600; }

#apijsBox div.close button {
	padding:0 1.4em; line-height:2.6em;
	font-size:0.8em; font-weight:700; text-transform:uppercase;
	color:white; border:2px solid white; border-radius:0 0 0 0.8em; background-color:black;
}

/* &&&&&&&&&&&&&&&&&& boutons de navigation &&& */
#apijsBox div.navigation button { position:absolute; top:5rem; bottom:5rem; background-color:transparent; opacity:0.5; }
#apijsBox div.navigation button:hover, #apijsBox div.navigation button:active, #apijsBox div.navigation button:focus { opacity:1; }
#apijsBox div.navigation button:disabled { opacity:0; cursor:default; }

#apijsBox div.navigation button span {
	display:block; width:2em; height:2em;
	border-top:2px solid white; border-left:2px solid white;
}

#apijsBox div.navigation button.prev { left:0; padding:0 20% 0 2em; }
#apijsBox div.navigation button.prev span { transform:rotate(-45deg); -webkit-transform:rotate(-45deg); }
#apijsBox div.navigation button.next { right:0; padding:0 2em 0 20%; }
#apijsBox div.navigation button.next span { transform:rotate(-225deg); -webkit-transform:rotate(-225deg); }

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& clavier &&& */
#apijsBox ul.kbd {
	display:flex; justify-content:center; margin:0; text-align:center;
	font-size:0.8em; white-space:nowrap; list-style:none; background-color:#777;
}

#apijsBox ul.kbd li { margin:0 0.5em; line-height:1.2rem; }
#apijsBox ul.kbd li kbd:last-child { margin-right:0.4em; }

#apijsBox kbd {
	margin-right:0.2em; padding:0 0.21em;
	font-size:inherit; font-weight:700; text-transform:uppercase; border:0; background-color:#888;
}

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& images &&& */
@font-face {
	src:url("../fonts/apijs/fontello.woff2") format("woff2"), url("../fonts/apijs/fontello.woff") format("woff");
	font-family:apijsFontello; font-weight:400; font-style:normal;
}

#apijsBox h1:before, #apijsBox div.btns button span:before, #apijsBox kbd:before, div.apijsplayer span.fnt {
	font-family:apijsFontello; font-style:normal; font-weight:400; font-variant:normal; text-transform:none; text-decoration:inherit;
	-moz-osx-font-smoothing:grayscale; -webkit-font-smoothing:antialiased;
}

#apijsBox h1:before { float:left; width:1.3em; text-align:center; font-size:1.5em; }
#apijsBox.information h1:before  { content:"\E80B\0000A0"; }
#apijsBox.confirmation h1:before { content:"\E80C\0000A0"; }
#apijsBox.options h1:before      { content:"\E80E\0000A0"; }
#apijsBox.upload h1:before       { content:"\F15B\0000A0"; }
#apijsBox.progress h1:before     { content:"\F1D8\0000A0"; }
#apijsBox.waiting h1:before      { content:"\F1D8\0000A0"; }
#apijsBox.download h1:before     { content:"\F15B\0000A0"; }
#apijsBox.print h1:before        { content:"\E80D\0000A0"; }
#apijsBox.error h1:before        { content:"\E80A\0000A0"; }
#apijsBox.warning h1:before      { content:"\E80A\0000A0"; }

#apijsBox div.btns button span:before { float:left; }
#apijsBox div.btns button.cancel span:before  { content:"\E801\0000A0"; }
#apijsBox div.btns button.confirm span:before { content:"\E800\0000A0"; }
#apijsBox div.btns button.browse span:before  { content:"\E802\0000A0"; }

#apijsBox kbd.start:before  { content:"\E807"; display:inline-block; transform:rotate(-45deg); -webkit-transform:rotate(-45deg); }
#apijsBox kbd.bottom:before { content:"\E804"; }
#apijsBox kbd.left:before   { content:"\E805"; }
#apijsBox kbd.right:before  { content:"\E806"; }
#apijsBox kbd.topk:before   { content:"\E807"; }
#apijsBox kbd.plus:before   { content:"\E809"; }
#apijsBox kbd.minus:before  { content:"\E808"; }

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& animations &&& */
@keyframes         apijsProgress {  0% { width:20%; } 50% { transform:translate(400%, 0); } 100% { width:20%; } }
@-webkit-keyframes apijsProgress {  0% { width:20%; } 50% { left:80%; } 100% { width:20%; } }
@keyframes         apijsFocus    { 50% { border-color:#BBB; } }
@-webkit-keyframes apijsFocus    { 50% { border-color:#BBB; } }
@keyframes         apijsReplace  { 50% { color:transparent; } 100% { content:attr(data-new); } }
@-webkit-keyframes apijsReplace  { 50% { color:transparent; } 100% { content:attr(data-new); } }