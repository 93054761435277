/**
 * Created V/08/05/2009
 * Updated J/12/09/2019
 *
 * Copyright 2008-2025 | Fabrice Creuzot (luigifab) <code~luigifab~fr>
 * https://github.com/luigifab/apijs
 *
 * This program is free software, you can redistribute it or modify
 * it under the terms of the GNU General Public License (GPL) as published
 * by the free software foundation, either version 2 of the license, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but without any warranty, without even the implied warranty of
 * merchantability or fitness for a particular purpose. See the
 * GNU General Public License (GPL) for more details.
 */

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& conteneurs &&& */
div.gallery img, div.album img {
	display:block; margin:auto; overflow:hidden;
	color:transparent; background-color:#EFEFEF; transition:background-color 0.3s;
}

div.gallery a:before, div.gallery a:after, div.album a:before, div.album a:after { display:none; content:""; }

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& gallery &&& */
div.gallery { display:inline-flex; flex-direction:column; padding:0.5em; vertical-align:top; }

div.gallery a { display:block; text-decoration:none; border:1px solid transparent; }
div.gallery ul { display:flex; flex-wrap:wrap; justify-content:space-around; margin:0.3em 0 0; padding:0; list-style:none; }
div.gallery li { margin:0.1em 0; padding:0; }
div.gallery li a { opacity:0.7; }
div.gallery li a.current { border:1px solid white; opacity:1; }

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& album &&& */
div.album { padding:0.4em; text-align:center; }

div.album dl {
	display:inline-block; margin:0.3em 0.1em; padding:0.5em 0.5em 0.45em; vertical-align:top;
	border:1px solid #EFEFEF; border-radius:0.6em; background-color:transparent; transition:border-color 0.3s, background-color 0.3s;
}

div.album dl.current { border:1px solid white; background-color:#EFEFEF; }
div.album dt a { display:block; text-decoration:none; border:1px solid transparent; }

div.album dd {
	margin:0.5em 0.5em 0; overflow:hidden;
	font-size:0.8em; white-space:nowrap; text-overflow:ellipsis; transition:color 0.3s;
}

div.album dl:hover { border-color:#CCC; background-color:#EFEFEF; }
div.album dl:hover img, div.album dl.current img { background-color:white; }